import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/img/left.png'
import _imports_1 from '../../assets/img/right.png'


const _hoisted_1 = { id: "login" }
const _hoisted_2 = {
  key: 0,
  class: "login_bottom"
}
const _hoisted_3 = { style: {"margin-left":"20px"} }

import { ref, computed, watch } from 'vue'
  import { useStore } from 'vuex';
  import FormPanel from './componnets/FormPanel.vue';
  import { useRoute } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      FormPanel
    }
  },
  __name: 'Index',
  setup(__props) {

  

  let tabIndex = ref(1)
  let store = useStore()
  let route = useRoute()

  

  let tabList = computed(() => {
    if(route.meta.type == 'login') {
      return [
        { title: '密码登录', key: 1 },
        { title: '验证码登录', key: 2 }
      ]
    }
    else if(route.meta.type == 'register'){
      return [
        { title: '注册', key: 1 }
      ]
    } else {
      return [
        { title: '重设密码', key: 1 }
      ]
    }
  })

  watch(() => route.name, (n, o) => {
    console.log(n)
    if(n && n != o) {
      tabIndex.value = 1;
    }
  })

  function tabClick(key: any) {
    tabIndex.value = key; 
    // (formRef.value as any).resetFields();
    // (formRef.value as any).clearValidate();
  }


    

return (_ctx: any,_cache: any) => {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
    _createElementVNode("div", {
      class: "login_panel",
      style: _normalizeStyle({ 'top': _ctx.$route.meta.type != 'register' ? '220px' : '60px' })
    }, [
      _createElementVNode("h3", {
        style: _normalizeStyle({ 'marginBottom': _ctx.$route.meta.type != 'register' ? '80px' : '30px' })
      }, _toDisplayString(_unref(store).state.commoInfo.title), 5),
      _createVNode(_component_a_tabs, {
        modelValue: _unref(tabIndex),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(tabIndex) ? (tabIndex).value = $event : tabIndex = $event)),
        onChange: tabClick
      }, _createSlots({
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabList), (item) => {
            return (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: item.key,
              title: item.title
            }, null, 8, ["title"]))
          }), 128))
        ]),
        _: 2
      }, [
        ( _ctx.$route.meta.type == 'register')
          ? {
              name: "extra",
              fn: _withCtx(() => [
                _createElementVNode("p", {
                  class: "tip pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/login')))
                }, _cache[3] || (_cache[3] = [
                  _createTextVNode("已有账号？"),
                  _createElementVNode("span", { class: "pointer" }, "点此登录", -1)
                ]))
              ]),
              key: "0"
            }
          : undefined,
        ( _ctx.$route.meta.type == 'forgetPassword')
          ? {
              name: "extra",
              fn: _withCtx(() => [
                _createElementVNode("p", {
                  class: "tip pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/login')))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "pointer" }, "点此登录", -1)
                ]))
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["modelValue"]),
      _createVNode(FormPanel, { tabIndex: _unref(tabIndex) }, null, 8, ["tabIndex"])
    ], 4),
    (_ctx.$route.meta.type == 'register')
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_unref(store).state.commoInfo.banquan), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(store).state.commoInfo.beian), 1)
        ]))
      : _createCommentVNode("", true),
    _cache[6] || (_cache[6] = _createElementVNode("img", { src: _imports_1 }, null, -1))
  ]))
}
}

})