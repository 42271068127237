export default {
  validateEmail(value: string, cb: any) {
    let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if(value) {
      if(!reg.test(value)) {
        return cb('请输入正确的电子邮箱');
      }else if(value.length < 6 || value.length > 50) {
        return cb('请输入6-50个字符以内的电子邮箱');
      }else {
        return cb();
      }
    }else {
      return cb();
    }
  },
  validPhoneOne(value: string, cb: any) {
    const isPhone = /^[1][3456789][0-9]{9}$/;
    if(value) {
      if(isPhone.test(value)) {
        cb();
      }else {  
        cb('请输入正确的手机号码');
      }
    }
  },
  validatePassword: (value: string, cb: any) => {
    let reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=|<>?,.\\/]).{6,20}$/;
    if(value) {
      if(!reg.test(value)) {
        return cb('密码长度需要在6-20位字符，且必须由数字、字母或符号三种类型组成');
      }else {
        return cb();
      }
    }
  },
  validateName: (value: string, cb: any) => {
    let reg = /^[a-z][a-z0-9_]{0,7}$/;
    if(value) {
      if(!reg.test(value)) {
        return cb('小写英文+数字+_的组合，且第一位字符必须为英文，8字以内');
      }else {
        return cb();
      }
    }else {
      return cb('请输入用户名');
    }
  }
}