import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "label_title" }
const _hoisted_2 = { className: "custom-radio-card-title" }

import { ref, getCurrentInstance, watch,computed, onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
  import { useRouter, useRoute } from 'vue-router';
  import validate  from '@/types/validate'
  import { useStore } from 'vuex';
  import { CONST } from '@/types/const'
  import md5 from 'js-md5'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'FormPanel',
  props: {
    tabIndex: {}
  },
  setup(__props: any) {

  function validatePasswordCom(value: any, cb: (a?: string) => void) {
    if (value !== formData.value.password) {
      cb('两次输入密码不一致，请重新输入');
    } else {
      cb();
    }
  }

  const props = __props

  
  let seconds = ref(0)
  let verifyCodeIsClicked = ref(false)
  let formRef = ref(null)
  let formData = ref({
    password: '',
    check: true
  })
  // let imageVerifyCode = ref('')
  let loginIn = ref(false);
  let store = useStore()
  let router = useRouter()
  let route = useRoute()
  let show = ref(false)
  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  let formList = computed(() => {
    if(route.meta.type == 'login') {
      if(props.tabIndex == 1 ) {
        return [
          { title: '用户名', key: 'account', type: 'text', place: '请输入用户名', rules: [{required:true,message:'请输入您的用户名称'}] },
          { title: '密码', key: 'password', type: 'password', place: '请输入密码', rules: [{required:true,message:'请输入密码'}]},
          // { title: '验证码', key: 'code', type: 'code', place: '请输入右侧验证码', rules: [{required:true,message:'请输入右侧验证码'}] },
        ]
      }else return [
        { title: '手机号码', key: 'phone', type: 'text', place: '请输入手机号码', 
          rules: [
            {required:true,message:'请输入手机号码'},
            { validator: validate.validPhoneOne, trigger: 'change' }
          ] 
        },
        { title: '验证码', key: 'verifyCode', type: 'phoneCode', place: '输入手机验证码', rules: [{required:true,message:'输入手机验证码'}]},
      ]
    }else if(route.meta.type == 'register'){
      return [
        { title: '用户类型', key: 'type', type: 'radio', value: '1', list: CONST.userTypeList, rules: [{required:true,message:'请输入选择用户类型'}] },
        { title: '用户名', key: 'account', type: 'text', place: '请输入用户名', rules: [
          {required:true,message:'请输入您的用户名称'},
          { validator: validate.validateName, trigger: 'change' }
        ] },
        { title: '密码', key: 'password', type: 'password', place: '请输入密码', rules: [
          { required:true,message:'请输入密码' },
          { validator: validate.validatePassword, trigger: 'change' }
        ]},
        { title: '确认密码', key: 'rePassword', type: 'password', place: '请再次输入密码', rules: [
          { required:true,message:'请再次输入密码' },
          { validator: validatePasswordCom, trigger: 'change' }
        ]},
        { title: '单位名称', key: 'unitName', type: 'text', place: '请输入单位名称', rules: [
          {required:true,message:'请输入单位名称'},
        ] },
        { title: '手机号码', key: 'phone', type: 'text', place: '请输入手机号码', rules: [
          {required:true,message:'请输入您的手机号码'},
          { validator: validate.validPhoneOne, trigger: 'change'}
        ]},
        { title: '验证码', key: 'verifyCode', type: 'phoneCode', place: '输入手机验证码', rules: [{required:true,message:'输入手机验证码'}]},
        { title: '联系人', key: 'contact', type: 'text', place: '请输入联系人', rules: [
          {required:true,message:'请输入联系人'},
        ] },
        { title: '电子邮箱', key: 'email', type: 'text', place: '请输入电子邮箱', rules: [
          { validator: validate.validateEmail, trigger: 'change' }
        ] },
        { title: '地址', key: 'address', type: 'text', place: '请输入地址'},
        // { title: '验证码', key: 'code', type: 'code', place: '请输入右侧验证码', rules: [{required:true,message:'请输入右侧验证码'}] },
        // { title: '', class: 'need_check', key: 'check', type: 'checkbox', value: true, rules: [{required:true,message:'请先勾选已同意并接受《法律声明和隐私权政策》'}] },
      ] 
    }else {
      return [
        { title: '手机号码', key: 'phone', type: 'text', place: '请输入手机号码', rules: [
          { validator: validate.validPhoneOne, trigger: 'change' }
        ] },
        { title: '验证码', key: 'verifyCode', type: 'phoneCode', place: '输入手机验证码', rules: [{ required:true, message:'输入手机验证码' }]},
        { title: '密码', key: 'password', type: 'password', place: '请输入密码', rules: [
          {required:true,message:'请输入密码'},
          { validator: validate.validatePassword, trigger: 'change' }
        ]},
        { title: '确认密码', key: 'rePassword', type: 'password', place: '请再次输入密码', rules: [
          {required:true,message:'请输入密码'},
          { validator: validatePasswordCom, trigger: 'change' }
        ]},
      ] 
    }
  })

  
  watch(() => props.tabIndex, (n, o) => {
    if(n && n != o) {
      resetClick()
    }
  }, {
    immediate: true
  })
  watch(() => route.name, (n, o) => {
    if(n && n != o) {
      resetClick();
    }
  }, {
    immediate: true
  })

  onBeforeUnmount(() => {
    (formRef.value as any).resetFields();
    (formRef.value as any).clearValidate();
  })

  onMounted(() => {
    formList.value.forEach((item: any) => {
      (formData as any).value[item.key] = item.value || ''
    });
  })

  function resetClick() {
    formList.value.forEach((item: any)=> {
      // console.log(item.key + '-' + item.value);
      (formData as any).value[item.key] = item.value || ''
    });
    if(formRef.value) {
      (formRef.value as any).resetFields();
      (formRef.value as any).clearValidate();
    }
  }

  // 获取手机验证码
  function getVerifyCode() {
    (formRef.value as any).validateField('phone', (valid: any) => {
      if(valid) {
        return globalProperties.$message.error(valid);
      }
      if(verifyCodeIsClicked.value) {
        return ;
      }
      verifyCodeIsClicked.value = true;
      // 接口验证是否发验证码
      globalProperties.$API.GetEmailVerifyCode({
        phone: (formData.value as any).phone,
        operate: route.name == 'register' ? 2 : route.name == 'login' ? 1 : 3
      }).then((res: any) => {
        if(res.data.code == 200) {
          seconds.value = 300;
          let timer = setInterval(() => {
            seconds.value--;
          }, 1000);
          setTimeout(() => {
            seconds.value = 0;
            clearInterval(timer);
          }, 300000);
          globalProperties.$message.success('手机验证码已发送，请在有效时间内使用!');
        } else {
          globalProperties.$message.error(res.data.msg || '接口出错了');
        }
        verifyCodeIsClicked.value = false;
      })
    })
  }

  // function getImageVerifyCode() {
  //   imageVerifyCode.value = globalProperties.$API.GetImageVerifyCode();
  // }

  function  handleSubmit({values, errors}: { [key: string]: any }) {
    if(errors) {
      return;
    }else {
      if(route.name == 'login') login()
      else register()
    }
  }


  function login() {
    if(!loginIn.value) loginIn.value = true;
    else globalProperties.$message.warning('登录中，请耐心等待…')
    let api = props.tabIndex == 1 ? 'LoginPass' : 'LoginPhone'
    let data = props.tabIndex == 1 ? {
      account: (formData.value as any).account,
      password: md5((formData.value as any).password)
    } : {
      phone: (formData.value as any).phone,
      verifyCode: (formData.value as any).verifyCode
    }
    globalProperties.$API[api]({ ...data }).then((res: any) => {
      if(res.data.code == 200) {
        loginIn.value = false;
        globalProperties.$message.success('操作成功')
        store.commit('login_type', 1)
        let previousPage = router.options.history.state.back
        let go = route.query.redirect ? route.query.redirect : previousPage ? previousPage : '/home' as any
        router.push(go)
      }else {
        loginIn.value = false;
        // getImageVerifyCode();
        globalProperties.$message.error(res.data.msg || '操作失败')
      }
    })
  }

  function register() {
    if(!loginIn.value) loginIn.value = true;
    else globalProperties.$message.warning(route.name == 'register' ? '注册中，请耐心等待…' : '重置密码中，请耐心等待…')
    let api = route.name == 'register' ?  'Register' : 'ResetPassword'
    globalProperties.$API[api]({ 
      ...formData.value, 
      password: md5((formData.value as any).password),
      rePassword: md5((formData.value as any).rePassword),
    }).then((res: any) => {
      if(res.data.code == 200) {
        loginIn.value = false;
        globalProperties.$message.success(route.name == 'register' ? '注册成功，请登录' : '重置密码成功，请登录');
        (formRef.value as any).resetFields();
        (formRef.value as any).clearValidate();
        router.replace('/login')
      }else {
        loginIn.value = false;
        // getImageVerifyCode();
        globalProperties.$message.error(res.data.msg || '操作失败')
      }
    })
  }

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    class: "form_panel",
    model: _unref(formData),
    ref_key: "formRef",
    ref: formRef,
    layout: "vertical",
    style: {width:'430px'},
    onSubmit: handleSubmit
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formList), (item, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createVNode(_component_a_form_item, {
            field: (item as any).key,
            label: (item as any).title,
            rules: (item as any).rules,
            "validate-trigger": ['change','input'],
            class: _normalizeClass((item as any).class),
            "hide-asterisk": _ctx.$route.name == 'register' ? false : true,
            onKeyup: _withKeys(login, ["enter","native"])
          }, {
            label: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", null, _toDisplayString((item as any).title), 1),
                ( (item as any).key== 'password' && _ctx.$route.meta.type == 'login')
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: "tip pointer",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/forgetPassword')))
                    }, _cache[2] || (_cache[2] = [
                      _createTextVNode(" 忘记密码？"),
                      _createElementVNode("span", { class: "pointer" }, "点此找回密码", -1)
                    ])))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              ((item as any).type == 'text' || (item as any).type == 'code' || (item as any).type == 'phoneCode')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([(item as any).type == 'code' ? 'code' : (item as any).type == 'phoneCode' ? 'phone_code' : ''])
                  }, [
                    _createVNode(_component_a_input, {
                      modelValue: (_unref(formData) as any)[(item as any).key],
                      "onUpdate:modelValue": ($event: any) => (((_unref(formData) as any)[(item as any).key]) = $event),
                      placeholder: (item as any).place,
                      onKeyup: _withKeys(login, ["enter","native"]),
                      "allow-clear": ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                    ((item as any).type == 'phoneCode')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_unref(seconds) === 0)
                            ? (_openBlock(), _createBlock(_component_a_button, {
                                key: 0,
                                type: "primary",
                                onClick: getVerifyCode
                              }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode("获取验证码")
                                ])),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_a_button, {
                                key: 1,
                                type: "primary",
                                disabled: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(seconds)) + "s 后再发送", 1)
                                ]),
                                _: 1
                              }))
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 2))
                : ((item as any).type == 'password')
                  ? (_openBlock(), _createBlock(_component_a_input_password, {
                      key: 1,
                      modelValue: (_unref(formData) as any)[(item as any).key],
                      "onUpdate:modelValue": ($event: any) => (((_unref(formData) as any)[(item as any).key]) = $event),
                      placeholder: (item as any).place,
                      "allow-clear": ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
                  : ((item as any).type == 'radio')
                    ? (_openBlock(), _createBlock(_component_a_radio_group, {
                        key: 2,
                        modelValue: (_unref(formData) as any)[(item as any).key],
                        "onUpdate:modelValue": ($event: any) => (((_unref(formData) as any)[(item as any).key]) = $event)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((item as any).list, (line) => {
                            return (_openBlock(), _createBlock(_component_a_radio, {
                              key: line.value,
                              value: line.value
                            }, {
                              radio: _withCtx(({ checked }) => [
                                _createVNode(_component_a_space, {
                                  align: "start",
                                  class: _normalizeClass(["custom-radio-card", { 'custom-radio-card-checked': checked }])
                                }, {
                                  default: _withCtx(() => [
                                    _cache[4] || (_cache[4] = _createElementVNode("div", { className: "custom-radio-card-mask" }, [
                                      _createElementVNode("div", { className: "custom-radio-card-mask-dot" })
                                    ], -1)),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", _hoisted_2, _toDisplayString(line.label), 1)
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["class"])
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue"]))
                    : ((item as any).type == 'checkbox')
                      ? (_openBlock(), _createBlock(_component_a_checkbox, {
                          key: 3,
                          modelValue: (_unref(formData) as any)[(item as any).key],
                          "onUpdate:modelValue": ($event: any) => (((_unref(formData) as any)[(item as any).key]) = $event)
                        }, {
                          default: _withCtx(() => [
                            _cache[6] || (_cache[6] = _createTextVNode(" 我已同意并接受")),
                            _createVNode(_component_a_button, { type: "text" }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode("《法律声明和隐私权政策》")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["field", "label", "rules", "class", "hide-asterisk"])
        ]))
      }), 128)),
      _createVNode(_component_a_form_item, { class: "login_btn" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            "html-type": "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$route.name == 'login' ? '登录' : _ctx.$route.name == 'login' ? '登录' : _ctx.$route.name == 'register' ? '注册' : '重设密码'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      ( _ctx.$route.meta.type == 'login' )
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "tip pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/register')))
          }, _cache[7] || (_cache[7] = [
            _createTextVNode("没有账号，"),
            _createElementVNode("span", { class: "pointer" }, "立即注册", -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})